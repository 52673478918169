import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlaidLink from 'react-plaid-link'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody } from '../../../components/layouts'


import {
	Button,
	Image,
	Loader
} from '../../../components/ui'

import {
	linkBank
} from '../../../factory'
import {PLAID_ENV} from '../../../factory/types'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'
import { navigate } from '@reach/router'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false
		}

		this.handleOnSuccess = this.handleOnSuccess.bind(this)
	}

	componentDidMount() {
		// this.setChargeable(getUrlVars().chargeable === 'true')
	}

	handleOnSuccess(plaid_token) {
		this.props.linkBank(plaid_token, this.props.token, ()=>navigate('/admin/profile/'), ()=> this.setState({showError: true}))
	}


	handleOnExit() {
		// handle the case when your user exits Link
	}

	render() {
		const {bank_pending, error, accountList} = this.props
		const {has_chargable_accounts, showError} = this.state

		const unlinkedAccounts = accountList.filter((acc) => !acc.is_linked)
		return (
			<Layout>
				<Container>
					<PhoneBody title={has_chargable_accounts ? 'Link Another Account' : "Link Funding Source"}>
						<Flex column style={{alignItems: 'center'}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png"}
								width={'125px'}
								marginTop={'25px'}
								height={'auto'}
								marginBottom={"0px"}
							/>
							<Heading as="h1" fontSize={'22px'}>Re-Link your accounts</Heading>
							<Paragraph>Please sign into the bank that contains the following accounts: </Paragraph>
							{unlinkedAccounts.map((acc) => <Paragraph style={{width: '100%', paddingLeft: '25%', textAlign: 'left'}}>{acc.name.substring(0, 22)}... {acc.mask}</Paragraph>)}
							<PlaidLink
								clientName="Flourish"
								env={PLAID_ENV}
								product={"transactions"}
								publicKey="d0d722e2447e775a33bc590c6687ac"
								onExit={this.handleOnExit}
								style={{borderRadius: '50px', width: 250, height: '2.5em', fontFamily: 'work-sans', background: theme.colors.sunglow, fontWeight: 900}}
								onSuccess={this.handleOnSuccess}>
								<Paragraph>Relink Bank</Paragraph>
							</PlaidLink>
						</Flex>
						{bank_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Linking Account...</Heading>
								</div>
							</div>
						</div>
						}
						{showError && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Heading as="h1" fontSize={24}>Uh oh!</Heading>
									<Paragraph>{error}</Paragraph>
									<Button onClick={()=>this.setState({showError: false})}>Close</Button>
								</div>
							</div>
						</div>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	accountList: PropTypes.array,
	linkBank: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error,
		accountList: state.userState.accountList,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
